import { Injectable } from "@angular/core";
import { InsuranceProduct, Vehicle } from "../../models";
import { VehicleService } from "../vehicle.service";
import { combineLatest, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { DealService } from "../deal/deal.service";
import { AppService } from "../app.service";
import { DealIncentivesService } from "../deal/deal-incentives.service";
import { ELECTRONIC_FILING_FEE, OREGON_REG_FEES_TABLE } from "src/app/app.config";
import { clone } from "ramda";
import { DealState } from "../../store/state";
import { FinancingSettings } from "../../models/calculations";
import Big from "big.js";
import { CalculationService } from "./calculation.service";
import { LeaseCalculationService } from "./lease-calculation.service";
import { ZipTable, ZipTableItem } from "../../../settings-module/models/zip-table";
import { pathOr } from "ramda";

@Injectable({
  providedIn: "root"
})
export class FormCalculationService {

  constructor(
    private vehicleService: VehicleService,
    private dealService: DealService,
    private appService: AppService,
    private incentivesService: DealIncentivesService,
    private calculationService: CalculationService,
    private leaseCalculationService: LeaseCalculationService,
  ) { }

  formServerCalculations$(): Observable<DealState> {

    return combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle(),
      this.calculationService.totalVehicleFinancePrice$({
        withoutDaysToFirstPay: true,
        actualTrade: true
      }),
      this.appService.selectFinancing(),
      this.calculationService.calculateTotalVehicleFinanceMonthlyPayment$(),
      this.leaseCalculationService.calcTotalMonthlyLeasePayment$(),
      this.appService.selectZipTaxTable(),
    ]).pipe(
      take(1),
      map(([deal, vehicle, totalFinancePrice, financingSettings, monthlyPayment, leaseMonthlyPayment, zipTable]:
             [DealState, Vehicle, number, FinancingSettings, number, number, ZipTable]) => {
        deal = clone(deal);
        if (deal.financeOptions.cashPurchase) {
          deal.insuranceProducts = deal.insuranceProducts.filter((product: InsuranceProduct) => {
            return (product.productKey !== "GAP") && (product.productKey !== "LEASEWEARTEAR");
          });
        }

        const vehicleCondition = this.vehicleService.vehicleCondition(vehicle);
        const regFeesTable = vehicleCondition === "new" ?
          OREGON_REG_FEES_TABLE.new :
          OREGON_REG_FEES_TABLE.used;

        const vsaSelectedTermPrice: number = deal.leaseOptions.leaseSelected ? 0 : this.calculationService.findSelectedTermPrice(deal.insuranceProducts, ["vsc", "vsa"]);
        const gapSelectedTermPrice: number = this.calculationService.findSelectedTermPrice(deal.insuranceProducts, ["gap"]);
        const selectSelectedTermPrice: number = this.calculationService.findSelectedTermPrice(deal.insuranceProducts, ["select"]);
        const maintenanceSelectedTermPrice: number = this.calculationService.findSelectedTermPrice(deal.insuranceProducts, ["maintenanceplan"]);
        const ewuSelectedTermPrice: number = deal.leaseOptions.leaseSelected ? this.calculationService.findSelectedTermPrice(deal.insuranceProducts, ["leaseweartear"]) : 0;
        const dealerAccessoriesTotal = this.calculationService.dealerAccessoriesTotal(vehicle, deal.selectedDealerAccessories);

        const retail = deal.financeOptionsEdits.retail === null ?
          vehicle.retail :
          deal.financeOptionsEdits.retail;

        // setup efFling fee default value based on zip code
        let defaultEFillingFee = ELECTRONIC_FILING_FEE;

        let titleFee = deal.financeOptionsEdits.titleFee === null
          ? deal.financeOptions.titleFee
          : deal.financeOptionsEdits.titleFee;

        const zipTaxTable = zipTable ? [...zipTable.oregon, ...zipTable.washington] : [];

        const foundZipTable = (zipTaxTable || []).find((zipTableItem: ZipTableItem) => {
          return pathOr(null, ["zipCode"], zipTableItem) === parseInt(deal.customer.zip, 10);
        });

        const isOregonZip = pathOr([], ["oregon"], zipTable)
          .find((zipTableItem: ZipTableItem) => {
            return pathOr(null, ["zipCode"], foundZipTable) === zipTableItem.zipCode;
          });

        const isWashingtonZip = pathOr([], ["washington"], zipTable)
          .find((zipTableItem: ZipTableItem) => {
            return pathOr(null, ["zipCode"], foundZipTable) === zipTableItem.zipCode;
          });

        if (isOregonZip) {
          titleFee = isOregonZip.titleFee;

          if (vehicle.mpg) {
            const oregonRegFee = regFeesTable.find(regFee => {
              return vehicle.mpg <= regFee.maxMpg && vehicle.mpg >= regFee.minMpg;
            });
            titleFee = oregonRegFee.titleFee;
          }
        }

        if (isWashingtonZip) {
          defaultEFillingFee = 0;
          titleFee = 0;
        }

        titleFee = deal.financeOptionsEdits.titleFee === null ? titleFee : deal.financeOptionsEdits.titleFee;

        const eFilingFee = deal.financeOptionsEdits.eFilingFee === null ?
          defaultEFillingFee :
          deal.financeOptionsEdits.eFilingFee;
        deal.financeOptions.amountFinanced = totalFinancePrice;
        deal.financeOptions.monthlyPayment = monthlyPayment;
        deal.leaseOptions.monthlyPayment = leaseMonthlyPayment;
        deal.financeOptions.daysToFirstPay = deal.financeOptions.daysToFirstPay ||
          financingSettings.financeDefault.daysToFirstPay;
        deal.financeOptions.eFilingFee = eFilingFee;
        if (deal.leaseOptions.leaseSelected) {
          deal.leaseOptions.acquisitionFee = deal.leaseOptions.acquisitionFee || financingSettings.leaseDefault.acquisitionFee;
          deal.leaseOptions.rateMarkup = deal.leaseOptions.rateMarkup || financingSettings.leaseDefault.newMarkup;
        }
        deal.financeOptions.vehicleLicRegFee = deal.financeOptions.totalRegFee;
        deal.financeOptions.totalRegFee = deal.financeOptions.regFee + deal.financeOptions.countyFee;
        deal.financeOptions.oadaOtherFees = deal.financeOptions.totalRegFee +
          selectSelectedTermPrice +
          maintenanceSelectedTermPrice +
          titleFee +
          eFilingFee +
          deal.financeOptions.docFees +
          vsaSelectedTermPrice +
          gapSelectedTermPrice +
          deal.financeOptions.oadaOtherPrice;

        const {adjustedPrice, incentivesApplied} = this.incentivesService.applyCashIncentives({
          price: 0,
          incentives: deal.incentives,
          leaseOptions: deal.leaseOptions,
          leaseSelected: deal.leaseOptions.leaseSelected,
          financeOptions: deal.financeOptions
        });
        const incentivesTotal = Math.abs(adjustedPrice);
        deal.financeOptions.selectedRebateValue = incentivesTotal;

        const leaseDownRebate = incentivesTotal + deal.financeOptions.downPayment - deal.leaseOptions.monthlyPayment;
        const nonLeaseDownRebate = incentivesTotal + deal.financeOptions.downPayment;
        deal.financeOptions.totalDownRebate = deal.leaseOptions.leaseSelected ? leaseDownRebate : nonLeaseDownRebate;

        let codPayment: number = deal.leaseOptions.leaseSelected ?
          (deal.financeOptions.downPayment - deal.leaseOptions.monthlyPayment) :
          deal.financeOptions.downPayment;

        if (codPayment !== 0) {
          codPayment = Math.round(codPayment * 100) / 100;
        }

        deal.financeOptions.totalDownRebateLabel = `COD $${codPayment}/Rebate $${incentivesTotal}`;

        if (deal.tradeIn.tradeEstimate < deal.tradeIn.payOff) {
          deal.tradeIn.negativeEquity = Math.abs(deal.tradeIn.tradeEstimate - deal.tradeIn.payOff);
        } else {
          deal.tradeIn.negativeEquity = 0;
        }
        if (deal.tradeIn2?.tradeEstimate < deal.tradeIn2?.payOff) {
          deal.tradeIn2.negativeEquity = Math.abs(deal.tradeIn2.tradeEstimate - deal.tradeIn2.payOff);
        } else {
          deal.tradeIn2.negativeEquity = 0;
        }

        let totalTradeInNegativeEquity = deal.tradeIn.payOff + deal.tradeIn2.payOff - deal.tradeIn.tradeEstimate - deal.tradeIn2.tradeEstimate;
        if (!totalTradeInNegativeEquity || totalTradeInNegativeEquity < 0) {
          totalTradeInNegativeEquity = 0;
        }

        const accessoriesTotal = this.calculationService.calcAccessoriesTotal(deal.accessories);
        deal.financeOptions.totalRetailPrice = retail + accessoriesTotal;
        deal.financeOptions.dealerFees = (eFilingFee) + deal.financeOptions.docFees;

        const date = new Date();
        deal.financeOptions.firstPaymentDue = new Date(date.setDate(date.getDate() +
          financingSettings.financeDefault.daysToFirstPay)).toISOString();

        // console.log("vsaSelectedTermPrice:", vsaSelectedTermPrice);

        deal.financeOptions.otherCharges = Big(vsaSelectedTermPrice)
          .add(gapSelectedTermPrice)
          .add(deal.financeOptions.totalRegFee)
          .add(titleFee)
          .add(deal.financeOptions.dealerFees || 0)
          .add(deal.financeOptions.totalTax)
          .add(selectSelectedTermPrice)
          .add(maintenanceSelectedTermPrice)
          .add(totalTradeInNegativeEquity)
          .round(2)
          .toNumber();

        // ISSUE #1833 - No not include negative trade in in total down payment
        const tradeValue = deal.tradeIn.tradeValue + deal.tradeIn2.tradeValue;
        const tradeValueNonNegative = tradeValue > 0 ? tradeValue : 0;
        const totalDownRebateTradeWithNegative = deal.financeOptions.totalDownRebate + tradeValue;
        const totalDownRebateTradeWithoutNegative = deal.financeOptions.totalDownRebate + tradeValueNonNegative;

        deal.financeOptions.totalDownRebateTrade = deal.financeOptions.totalDownRebate + tradeValue;
        deal.financeOptions.totalDownRebateNonNegTrade = deal.financeOptions.totalDownRebate + tradeValueNonNegative;

        deal.financeOptions.paymentTotal = Big(deal.financeOptions.monthlyPayment)
          .times(deal.financeOptions.selectedFinancingTerm).round(2).toNumber();

        deal.financeOptions.totalSalePrice = deal.financeOptions.paymentTotal +
          totalDownRebateTradeWithoutNegative;
        deal.financeOptions.paymentFinanceCharge = Big(deal.financeOptions.monthlyPayment)
          .times(deal.financeOptions.selectedFinancingTerm)
          .minus(deal.financeOptions.amountFinanced)
          .round(2)
          .toNumber();
        deal = this.setOadaOther(deal);
        deal.financeOptions.oadaTotalOtherCharges = Big(vsaSelectedTermPrice)
          .add(gapSelectedTermPrice)
          .add(deal.financeOptions.totalRegFee)
          .add(titleFee)
          .add(deal.financeOptions.dealerFees)
          .add(deal.financeOptions.totalTax)
          .add(deal.leaseOptions.leaseSelected ? deal.leaseOptions.acquisitionFee : 0)
          .round(2)
          .toNumber();

        /*console.log("oadaTotalOtherCharges",
          deal.financeOptions.oadaTotalOtherCharges,
          vsaSelectedTermPrice,
          gapSelectedTermPrice,
          deal.financeOptions.totalRegFee,
          titleFee,
          deal.financeOptions.dealerFees,
          deal.financeOptions.totalTax,
          deal.leaseOptions.leaseSelected ? deal.leaseOptions.acquisitionFee : 0
        );

        console.log("more details", isOregonZip ? "yes" : "no", vehicle.mpg, regFeesTable);*/


        deal.financeOptions.oadaTotalCashSalePrice = retail +
          (deal.leaseOptions.leaseSelected ? ewuSelectedTermPrice : 0) +
          maintenanceSelectedTermPrice +
          selectSelectedTermPrice +
          dealerAccessoriesTotal +
          accessoriesTotal -
          deal.totalDealerAccCost;

        deal.financeOptions.amountDue = deal.financeOptions.oadaTotalCashSalePrice +
          deal.financeOptions.oadaTotalOtherCharges - totalDownRebateTradeWithNegative;

        deal.financeOptions.amountFinancedPB = deal.financeOptions.oadaTotalCashSalePrice +
          deal.financeOptions.otherCharges - totalDownRebateTradeWithNegative;

        // console.log('deal.financeOptions.amountDue:', deal.financeOptions.amountDue)

        // deal.financeOptions.amountDue = deal.financeOptions.oadaTotalCashSalePrice +
        //   deal.financeOptions.oadaTotalOtherCharges - totalDownRebateTradeWithNegative;

        const pack = vehicleCondition === "used" ? 650 : 0;

        deal.financeOptions.catPrice = deal.financeOptions.oadaTotalCashSalePrice +
          gapSelectedTermPrice +
          vsaSelectedTermPrice +
          deal.financeOptions.docFees -
          pack;
        deal.financeOptions.catCostAdjust = Big(vehicle.order.price)
          .add(gapSelectedTermPrice)
          .add(vsaSelectedTermPrice)
          .add(maintenanceSelectedTermPrice)
          .add(selectSelectedTermPrice)
          .times(".35")
          .round(2)
          .toNumber();
        deal.financeOptions.catAdjustedSale = Big(deal.financeOptions.catPrice)
          .minus(deal.financeOptions.catCostAdjust).times(".057").round(2).toNumber();
        return deal;
      }));
  }

  setOadaOther(deal: DealState): DealState {
    const {financeOptions, leaseOptions, customer} = deal;
    if (leaseOptions.leaseSelected && customer.state.toLowerCase() === "or") {
      financeOptions.oadaOtherDesc = "CorpActTaxRecv + PrivTax + AcqFee";
      financeOptions.oadaOtherPrice = Big(financeOptions.catTax)
        .add(financeOptions.privilegeTax)
        .add(leaseOptions.acquisitionFee).toNumber();
      return deal;
    }
    if (leaseOptions.leaseSelected && customer.state.toLowerCase() !== "or") {
      financeOptions.oadaOtherDesc = "CorpActTaxRecv + SalesTax + AcqFee";
      financeOptions.oadaOtherPrice = Big(financeOptions.catTax)
        .add(financeOptions.salesTax)
        .add(financeOptions.totalStateTaxes)
        .add(leaseOptions.acquisitionFee)
        .toNumber();
      return deal;
    }
    if (customer.state.toLowerCase() === "or") {
      financeOptions.oadaOtherDesc = "CorpActTaxRecv + PrivTax";
      financeOptions.oadaOtherPrice = Big(financeOptions.catTax)
        .add(financeOptions.privilegeTax).toNumber();
      return deal;
    }
    if (customer.state.toLowerCase() !== "or") {
      financeOptions.oadaOtherDesc = "CorpActTaxRecv + SalesTax";
      financeOptions.oadaOtherPrice = Big(financeOptions.catTax)
        .add(financeOptions.totalStateTaxes)
        .add(financeOptions.salesTax).toNumber();
      return deal;
    }

    return deal;
  }

}
