import { NavigationExtras, Router } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { Actions, ofType } from "@ngrx/effects";
import { DealState, DealStatus } from "src/app/clearpath-module/store/state";
import * as dealActions from "src/app/clearpath-module/store/actions/deal.actions";
import * as vehicleActions from "src/app/clearpath-module/store/actions/vehicle.actions";
import * as appActions from "src/app/clearpath-module/store/actions/app.actions";
import { v1 as uuidv1 } from "uuid";
import { AppService, CalculationService, DealService, LeaseCalculationService, VehicleService } from "src/app/clearpath-module/services";
import { AlertService, CanComponentDeactivate, PubnubService } from "src/app/shared-module/services";
import { FinanceDefault, LeaseDefault } from "src/app/settings-module/models";
import { User } from "src/app/user-admin-module/models";
import { DealDefaults, DealDefaultsService } from "src/app/clearpath-module/services/deal/deal-defaults.service";
import { DealIncentive, SelectedIncentive } from "src/app/settings-module/models/incentives";
import { Accessory, Buyer, FinanceOptions, FinancingSettings, LeaseOptions, LeaseResidual, TradeIn, Vehicle, VehicleNeeds, } from "src/app/clearpath-module/models";
import { AuthService } from "src/app/auth-module/services";
import { DealIncentivesService } from "src/app/clearpath-module/services/deal/deal-incentives.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { pathOr } from "ramda";
import { selectNetTradeInValue } from "../../../clearpath-module/store/selectors/deal";
import { Store } from "@ngrx/store";
import { PrintDisclosure, PrintDisclosureForm } from "../../utils/printDisclosure";
import * as dealSelectors from "../../../clearpath-module/store/selectors/deal";
import swal, { SweetAlertOptions } from "sweetalert2";
import { AlternateVsaDialogComponent } from "../../components/dialogs/alternate-vsa-dialog/alternate-vsa-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-master-sales-manager-writeup",
  templateUrl: "./master-sales-manager-writeup.component.html",
  styleUrls: ["./master-sales-manager-writeup.component.scss"]
})
export class MasterSalesManagerWriteupComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  currentCriticalMemoText = "";
  additionalCriticalMemoText = "";
  saveCountWithMetConditions = 0;
  viewVsaModalPriceLimit = 30000;

  private unsubscribe$ = new Subject();
  private uiState = {
    activeViewId: "payment",
    viewTypes: {
      customer: "customer",
      vehicle: "vehicle",
      payment: "payment"
    },
    incentivesAssigned: false,
    waitOnAction: false,
    unsavedChanges: false
  };
  salesManagerVerified = false;
  vehicleForm;
  selectedCashIncentivesTotal = 0;
  totalVehicleFinancePrice$;
  vehicleBaseFinanceAmount$;
  vehicles: Vehicle[];

  // Clearpath Store State
  deal: DealState;
  dealDefaults: DealDefaults;
  vehicle: Vehicle;
  financingSettings: FinancingSettings;
  leaseResiduals: LeaseResidual[];
  baseVehiclePrice: number;
  customizedVehiclePrice: number;
  vehicleRetail: number;
  user: User;
  hideCoBuyer = true;
  hideTradeInOwner = true;
  hideTradeInOwner2 = true;
  hideTradeIn = true;
  hideTradeIn2 = true;
  hideTradeIn2Owner = true;
  hideTradeIn2Owner2 = true;
  selectedIncentives: SelectedIncentive[];
  prevAccessories;
  refreshAccRequired = true;

  // Derived State
  vehicleCondition: string;

  taxCalculated: boolean;

  public loaded = false;

  // selectDealCounter = 0;

  salesManagerMasterForm: FormGroup = this.formBuilder.group({
    contractDate: [""],
    downPayment: [0],
  });

  // Calculation Selectors
  tradeInNetValue$: Observable<number> = this.store.select(selectNetTradeInValue);

  constructor(
    private store: Store<DealState>,
    private router: Router,
    private alertService: AlertService,
    private appService: AppService,
    private calcService: CalculationService,
    private leaseCalcService: LeaseCalculationService,
    private dealService: DealService,
    private vehicleService: VehicleService,
    private dealDefaultsService: DealDefaultsService,
    private authService: AuthService,
    private incentivesService: DealIncentivesService,
    private leaseCalculationService: LeaseCalculationService,
    private pubsub: PubnubService,
    private actions$: Actions,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) { }


  ngOnInit() {
    combineLatest([
      this.appService.selectZipTaxTable(),
      this.appService.selectFinancing(),
      this.appService.selectNewResiduals(),
      this.appService.selectCertUsedResiduals(),
      this.appService.selectDealer(),
      this.authService.selectUser(),
      this.vehicleService.selectVehicle(),
    ]).pipe(takeUntil(this.unsubscribe$))
      .subscribe((
          [zipTable, financingSettings, newResiduals, certUsedResiduals, dealer, user, vehicle]: [any, FinancingSettings, any, any, any, User, Vehicle]) => {
          if (zipTable && financingSettings && newResiduals && certUsedResiduals && dealer && vehicle.stockNumber) {
            //console.log("Desking Writeup Initialized", {zipTable, financingSettings, newResiduals, certUsedResiduals, dealer, user, vehicle});
            this.user = user;
            this.loaded = true;
            this.subToDealData();
            this.subToSettingsData();
            this.subToVehicleData();
            this.watchDealChanges();
            this.initObservables();
            this.subToIncentives();
          }
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.dealService.dispatchClearDeal();
    this.vehicleService.dispatchClearVehicle();
  }

  canDeactivate(): boolean {
    const {unsavedChanges} = this.uiState;
    const navigateAway = unsavedChanges ? confirm("Discard Changes?") : true;
    return navigateAway;
  }

  // INITIALIZATION

  initObservables() {
    this.totalVehicleFinancePrice$ = this.calcService.totalVehicleFinancePrice$({
      withoutDaysToFirstPay: true,
      actualTrade: true,
      finance: true
    });
  }

  private subToDealData() {

    combineLatest([
      this.dealService.selectFinanceOptionsEdits(),
      this.vehicleService.selectVehicle(),
    ]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(([financeOptionsEdits, vehicle]: [FinanceOptions, Vehicle]) =>
        this.vehicleRetail = financeOptionsEdits.retail === null ? vehicle.retail : financeOptionsEdits.retail
      );

    this.calcService.baseVehiclePrice$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(baseVehiclePrice => {
        this.baseVehiclePrice = baseVehiclePrice;
      });

    this.calcService.customizedVehiclePrice$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customizedVehiclePrice) => {
        this.customizedVehiclePrice = customizedVehiclePrice;
        if (
          this.customizedVehiclePrice &&
          pathOr(false, ["deal", "customer", "zip"], this) &&
          !this.taxCalculated
        ) {
          this.dealService.dispatchCalcTax();
          this.taxCalculated = true;
        }
      });

    combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle(),
      this.dealDefaultsService.getDealDefaults$(),
      this.appService.selectFinancing(),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([deal, vehicle, dealDefaults, financingSettings]: [DealState, Vehicle, DealDefaults, FinancingSettings]) => {
        // (amirchandani) log the number of times this section of the code is run
        // this.selectDealCounter++;
        // console.log("Started Select Deal #" + this.selectDealCounter);
        this.deal = deal;
        this.dealDefaults = dealDefaults;
        this.financingSettings = financingSettings;
        if (this.customizedVehiclePrice && this.deal.customer.zip && !this.taxCalculated) {
          this.dealService.dispatchCalcTax();
          this.dealService.dispatchCalcFees();
          this.taxCalculated = true;
        }
        this.setVehicle(vehicle);
        if (vehicle.stockNumber &&
          !(vehicle.stockNumber === pathOr("", ["vehicle", "stockNumber"], this))) {
          // this.dealService.dispatchApplyInitialInsuranceProducts();
          this.dealService.applyInitialDefaults();
        }
        if (
          deal.dealId &&
          (!vehicle.incentives.length || !(deal.incentives || []).length) &&
          !this.uiState.incentivesAssigned
        ) {
          this.uiState.incentivesAssigned = true;
          this.incentivesService.assignDefaultIncentives();
        }

        if (deal.customer && deal.contractDate) {
          const contractDate = new Date(this.deal.contractDate);
          const contractDateString = (contractDate.getMonth() + 1) + '-' + contractDate.getDate() + '-' + contractDate.getFullYear();
          //console.log("Critical Memo, generating sentence.")

          this.currentCriticalMemoText = vehicle.criticalMemo;

          this.additionalCriticalMemoText = "";

          if (deal.insuranceProducts)
            deal.insuranceProducts.forEach(product => {
              //console.log("Product: ", product)
              if (product.productKey === "GAP")
                this.additionalCriticalMemoText += "GAP, ";
              else if (product.productKey === "MAINTENANCEPLAN")
                this.additionalCriticalMemoText += "PPM, ";
              else if (product.productKey === "LEASEWEARTEAR" && deal.leaseOptions.leaseSelected)
                this.additionalCriticalMemoText += "EWU, ";
              else if (product.productKey === "VSC")
                this.additionalCriticalMemoText += "VSA, ";
              else if (product.productKey === "SELECT")
                this.additionalCriticalMemoText += "CCE, ";
            });

          // format the additional critical memo text depending on any products in deal and check existing critical memo in vehicle.
          this.additionalCriticalMemoText = !this.additionalCriticalMemoText ? "" : (
            (!this.currentCriticalMemoText ? '' : ". ") +
            `${this.additionalCriticalMemoText}purchased at time of sale ${contractDateString} by ${deal.customer.firstName} ${deal.customer.lastName}.`
          );

          //console.log("Critical Memo, sentence = ", !this.criticalMemoText ? '[NONE]' : this.criticalMemoText);
        }

        // if vehicle needs is not set or miles Driven year is set to zero, set vehicle needs to default
        if (deal.dealId && (!deal.vehicleNeeds || !deal.vehicleNeeds.milesDrivenPerYear)) {
          deal.vehicleNeeds = this.dealDefaults.vehicleNeeds;
        }

        // const { adjustedPrice, incentivesApplied } = this.incentivesService.applyCashIncentives(
        //   0,
        //   this.deal.incentives,
        //   this.deal.leaseOptions.leaseSelected,
        //   this.deal.financeOptions.selectedCreditTier,
        //   this.deal.leaseOptions.selectedLeaseTerm,
        // );
        // this.selectedCashIncentivesTotal = Math.abs(adjustedPrice);

        this.salesManagerMasterForm.patchValue({
          downPayment: !isNaN(deal.financeOptions.downPayment) ? deal.financeOptions.downPayment : 0,
          contractDate: deal.contractDate ? deal.contractDate : ''
        });

        this.vehicleBaseFinanceAmount$ = this.calcService.vehicleBaseFinanceAmount$();

        // NOTE: (amirchandani, issue #1493)
        // When the case that an incentive rate is selected and a pre-existing customer provided interest rate is set, both
        // rates will be selected which is not correct.  In this case, we check for this condition, and if both rates are selected
        // the custom rate will be prioritized over the incentive rate.
        if (deal.financeOptions.customSelected && deal.financeOptions.customerProvidedInterestRate > 0 && deal.financeOptions.incentiveSelected) {
          deal.financeOptions.incentiveSelected = false;
        }

        // only toggle section show if the ui state is pristine
        if (!this.uiState.unsavedChanges) {

          // If the co buyer is hidden and has the min required field defined, show it.
          if (this.hideCoBuyer && this.deal.coSigner.lastName) {
            this.hideCoBuyer = false;
          }

          // If the trade in owner is hidden and has the min required field defined, show it.
          if (this.hideTradeInOwner && this.deal.tradeInOwner.lastName) {
            this.hideTradeInOwner = false;
          }

          // If the trade in owner 2 is hidden and has the min required field defined, show it.
          if (this.hideTradeInOwner2 && this.deal.tradeInOwner2.lastName) {
            this.hideTradeInOwner2 = false;
          }

          // If the trade in is hidden and has the min required field defined, show it.
          if (this.hideTradeIn && (this.deal.tradeIn.tradeValue > 0 || this.deal.tradeIn.tradeEstimate > 0)) {
            this.hideTradeIn = false;
          }

          // If the trade in 2 is hidden and has the min required field defined, show it.
          if (this.hideTradeIn2 && (this.deal.tradeIn2.tradeValue > 0 || this.deal.tradeIn2.tradeEstimate > 0)) {
            this.hideTradeIn2 = false;
          }

          // If the trade in 2 is hidden and has the min required field defined, show it.
          if (this.hideTradeIn2Owner && this.deal.tradeIn2Owner.lastName) {
            this.hideTradeIn2Owner = false;
          }

          // If the trade in 2 owner 2 is hidden and has the min required field defined, show it.
          if (this.hideTradeIn2Owner2 && this.deal.tradeIn2Owner2.lastName) {
            this.hideTradeIn2Owner2 = false;
          }

        }

      });

    // Deal API Http Success
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(dealActions.dealApiSuccess),
    ).subscribe(({deal, actionType}: { deal: DealState, actionType: string }) => {
      this.flagWaitOnAction(false);
      this.flagUnsavedChanges(false);
      this.alertService.clear();
      if (this.deal.insuranceProducts.length > 0 && this.vehicle && actionType === 'save') {
        let hasVsaOrPpm = false;
        deal.insuranceProducts.forEach(product => {
          if (product.productKey === 'VSC' || product.productKey === 'MAINTENANCEPLAN') {
            hasVsaOrPpm = true;
          }
        });
        if (hasVsaOrPpm) {
          console.log("Deal", deal);
          // if vehicle is new and deal wholesale is less than limit & also the lien holder "8478178" is selected (TOYOTA MOTOR CREDIT CORPORATION)
          if (
            deal?.lienHolder?.id === "8478178" &&
            ((!this.vehicle.isUsed && this.vehicle.order.price < this.viewVsaModalPriceLimit) ||
              (this.vehicle.isUsed && (this.deal.financeOptionsEdits.retail || this.vehicle.retail) < this.viewVsaModalPriceLimit))
          ) {
            this.saveCountWithMetConditions++;
            if (this.saveCountWithMetConditions === 1) {
              this.dialog.open(AlternateVsaDialogComponent, {
                width: '400px',
                autoFocus: false,
              });
            }
          }
        }
      }
    });

    // Deal API Http Failure
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(dealActions.dealApiFailure),
    ).subscribe(action => {
      this.flagWaitOnAction(false);
      this.alertService.error(action.error);
    });

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(vehicleActions.setVehicleByLookup),
    ).subscribe(() => {
      this.flagUnsavedChanges(true);
      this.flagWaitOnAction(false);
    });

  }

  subToIncentives() {
    this.incentivesService
      .selectedCashIncentives$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((selectedIncentives: SelectedIncentive[]) => {
        this.selectedIncentives = selectedIncentives;
        this.selectedCashIncentivesTotal = selectedIncentives.reduce(
          (sum: number, incentive: SelectedIncentive) => sum + incentive.value
          , 0
        );
      });
  }

  private subToSettingsData() {
    this.appService.selectFinancing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((financingSettings: FinancingSettings) => {
        this.financingSettings = financingSettings;
      });
  }

  private subToVehicleData() {

    this.vehicleService.selectVehicles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((vehicles: Vehicle[]) => {
        this.vehicles = vehicles;
      });
    // this.actions$.pipe(
    //   takeUntil(this.unsubscribe$),
    //   ofType(vehicleActions.setVehicle),
    // ).subscribe(({ vehicle }) => {
    //   this.setVehicle(vehicle);
    // });

    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(vehicleActions.getVehicleFailure, vehicleActions.updateVehicleFailure),
    ).subscribe(action => {
      this.alertService.error(action.error);
    });
  }

  setVehicle(data: Vehicle) {
    // use object assign so that child component can detect changes
    this.vehicle = Object.assign({}, data);
    // this.deal.stockNumber = this.vehicle.stockNumber;
    this.vehicleCondition = this.vehicleService.vehicleCondition(data);
    this.refreshAccRequired = true;
    this.getVehicleLeaseResiduals();
  }

  private getVehicleLeaseResiduals() {
    this.leaseCalcService.getLeaseResiduals()
      .pipe(take(1))
      .subscribe((data: LeaseResidual[]) => {
        this.leaseResiduals = data;
      });
  }

  private watchDealChanges() {
    this.actions$.pipe(
      takeUntil(this.unsubscribe$),
      ofType(appActions.pubsubMessage),
    ).subscribe(({messageName, dealId}) => {
      if (messageName === this.pubsub.Messages.DealSubmitted) {
        if (this.deal.dealId === dealId) {
          this.dealService.dispatchGetByDealId(this.deal.dealId);
          alert("Deal Updated by Sales Person");
        }
      }
      if (messageName === this.pubsub.Messages.DealRetracted) {
        if (this.deal.dealId === dealId) {
          this.dealService.dispatchGetByDealId(this.deal.dealId);
          alert("Sales person is changing the deal");
        }
      }
    });
  }

  // DEAL API ACTIONS

  taxLookup() {
    console.log("Tax Lookup");
    this.dealService.taxLookupDeal = this.deal;
    this.dealService.taxLookupVehicle = this.vehicle;

    let state = '';
    if (this.deal?.customer?.state) {
      state = this.deal.customer.state.trim().toLowerCase();
    }

    if (state === 'wa' || state === 'washington') {
      const navigationExtras: NavigationExtras = {
        queryParams: {},
      };
      this.router.navigate([{
        outlets: {
          modal: ["tax-lookup"]
        },
      }], navigationExtras).then();
    } else {
      swal.fire({
        title: "Unsupported State",
        html: `The customer's state of residency is <b>${this.deal?.customer?.state}</b>.  Currently, only the following states can use the Tax Lookup tool:<b> Washington (WA)</b>.`,
        icon: "error",
        showConfirmButton: true,
        confirmButtonText: "OK",
      } as SweetAlertOptions).then();
    }
  }

  updateDeal() {
    if (this.waitOnAction || this.unsavedChanges) { return; }
    if (this.dealStatus === DealStatus.Submitted) {
      this.flagWaitOnAction(true);
      this.dealService.dispatchUpdateDeal();
    }
  }

  approveDeal() {
    let confirmed = true;
    /* No more need for this since only sales managers and admin have access to Desking.
    if (!this.salesManagerVerified) {
      confirmed = confirm("Not all changes are approved. Continue?");
    }*/
    if (confirmed) {
      if (this.waitOnAction || this.unsavedChanges) { return; }
      this.flagWaitOnAction(true);
      this.dealService.dispatchApproveDeal(this.deal.dealId);
    }
  }

  printDeal() {
    if (this.waitOnAction || this.unsavedChanges) { return; }
    this.flagWaitOnAction(true);
    this.dealService.dispatchPrintDeal(this.deal.dealId);
  }

  completeDeal() {
    if (this.waitOnAction || this.unsavedChanges) { return; }
    const dealPrinting = this.dealStatus === DealStatus.Printing;
    const dealPrinted = this.dealStatus === DealStatus.Printed;

    if (dealPrinting || dealPrinted) {
      this.flagWaitOnAction(true);
      this.dealService.dispatchCompleteDeal();
    }
  }

  declineDeal() {
    if (this.waitOnAction || this.unsavedChanges) { return; }
    if (this.dealStatus === DealStatus.Completed) { return; }
    if (confirm("Decline Deal?")) {
      this.flagWaitOnAction(true);
      this.dealService.dispatchDeclineDeal(this.deal.dealId);
    }
  }

  saveDealChanges() {
    if (this.waitOnAction || this.unsavedChanges === false) { return; }
    // if (this.dealStatus === DealStatus.Completed) { return; }
    const noVehicle = !this.deal.stockNumber;

    if (noVehicle) {
      this.alertService.error("Deal requires a stock number.");
      return;
    }

    this.flagWaitOnAction(true);
    const newDeal = !this.deal.dealId;

    // this.updateVehicleFormDealVehicle();
    if (newDeal) {
      this.deal.dealId = uuidv1(); // create unique id for the deal
      this.deal.salesManager = this.user.employeeId;
      this.dealService.dispatchSalesManagerCreateDeal(this.deal);
    } else {
      this.dealService.dispatchSaveDeal(this.deal);
    }
    this.refreshAccRequired = true;
    this.vehicleService.dispatchUpdateVehicle(this.vehicle);
  }

  cancelDealChanges() {
    if (this.waitOnAction || this.unsavedChanges === false) { return; }
    const newDeal = !this.deal.dealId;

    if (newDeal) {
      if (confirm("Discard New Deal?")) {
        this.flagUnsavedChanges(false);
        this.router.navigate(["/clearpath/sales-manager/list"]);
      }
    } else {
      this.router.navigate(["/clearpath/sales-manager/list"]);
      // this.flagWaitOnAction(true);
      // if (confirm("Cancel Changes?")) {
      //   // this.dealService.dispatchGetByDealId(this.deal.dealId);
      // }
    }
  }

  autoSubmitForm = () => {
    const {pristine, invalid} = this.salesManagerMasterForm;
    if (pristine || invalid) { return; }

    let {contractDate, downPayment} = this.salesManagerMasterForm.value;
    downPayment = parseFloat(downPayment);
    downPayment = !isNaN(downPayment) ? downPayment : 0;
    this.dealService.dispatchSetFinanceOptions({downPayment});

    if (typeof contractDate === "object") {
      const tempDate = new Date(contractDate).toISOString();
      contractDate = tempDate.split("T")[ 0 ];
    }

    this.store.dispatch(dealActions.setContractDate(contractDate));

    this.salesManagerMasterForm.markAsUntouched();
    this.salesManagerMasterForm.markAsPristine();
    this.flagUnsavedChanges(true);
  };

  // DEAL STATE OPERATIONS

  updateDealType(type: string) {
    this.dealService.dispatchSetDealType(type);
    this.flagUnsavedChanges(true);
  }

  updateAccessories(accessories: Accessory[]) {
    this.refreshAccRequired = true;
    this.dealService.dispatchSetAccessories(accessories);
    this.flagUnsavedChanges(true);
  }

  updateFinanceOptions(financeOptions: Partial<FinanceOptions>) {
    /* #1464: There is no need to set the financing term here since the
    dispatchSetFinanceOptions call below can accept a partial finance options object.
    if (
    (this.deal.financeOptions.selectedFinancingTerm !== financeOptions.selectedFinancingTerm) ||
    !this.deal.financeOptions.selectedFinancingTerm) {
      this.dealService.setFinancingTerm(
        financeOptions.selectedFinancingTerm ||
        financeOptions.customerProvidedFinancingTerm
      );
    }*/
    this.dealService.dispatchSetFinanceOptions(financeOptions);
    this.flagUnsavedChanges(true);
  }

  updateLeaseOptions(leaseOptions: Partial<LeaseOptions>) {
    this.dealService.dispatchSetLeaseOptions(leaseOptions);
    //this.dealService.dispatchSetIncentives([]);
    this.flagUnsavedChanges(true);
  }

  updateLeasingTerm(leasingTerm) {
    this.dealService.setLeasingTerm(leasingTerm);
    this.flagUnsavedChanges(true);
  }

  updateIncentives(incentives: DealIncentive[]) {
    this.dealService.dispatchSetIncentives(incentives);
    this.flagUnsavedChanges(true);
  }

  updateSalesManager(salesManager: User) {
    this.dealService.dispatchSetSalesManager(salesManager);
    this.flagUnsavedChanges(true);
  }

  updateSalesPerson(salesPerson: User) {
    this.dealService.dispatchSetSalesPerson(salesPerson);
    this.flagUnsavedChanges(true);
  }

  /*updateVehicleForm(vehicleForm) {
    this.vehicleForm = vehicleForm;
  }*/

  /*updateVehicleFormDealVehicle() {
    const {plateExpires, plateNumber} = this.vehicleForm;
    const vehicleFormFields = this.vehicleForm;
    delete vehicleFormFields.plateExpires;
    delete vehicleFormFields.plateNumber;
    this.dealService.dispatchSetPlate({plateExpires, plateNumber});
    this.vehicleService.dispatchSetVehicle(vehicleFormFields);
  }*/

  // DATA ACCESS

  get customer(): Buyer {
    return this.deal.customer;
  }

  /*get tradeOwner() {
    return this.deal.tradeInOwner;
  }*/

  get coSigner(): Buyer {
    return this.deal.coSigner;
  }

  get dealStatus(): string {
    return this.deal.status;
  }

  get financeDefault(): FinanceDefault {
    return this.financingSettings.financeDefault;
  }

  get selectedCreditTier(): number {
    return this.deal.financeOptions.selectedCreditTier || 0;
  }

  get leaseDefault(): LeaseDefault {
    return this.financingSettings.leaseDefault;
  }

  get leaseOptions(): LeaseOptions {
    return this.deal.leaseOptions;
  }

  get stockNumber(): string {
    return this.deal.stockNumber;
  }

  get tradeIn(): TradeIn {
    return this.deal.tradeIn;
  }

  get vehicleNeeds(): VehicleNeeds {
    return this.deal.vehicleNeeds;
  }

  get accessories(): Accessory[] {
    if (this.prevAccessories && !this.refreshAccRequired) {
      return this.prevAccessories;
    }
    this.prevAccessories = (this.deal?.accessories || []).filter(a => !a.hidden);
    this.refreshAccRequired = false;
    return this.prevAccessories;
  }

  // UI CONTROL & RENDERING

  onSelectView(id: string) {
    const {viewTypes} = this.uiState;
    this.uiState.activeViewId = viewTypes[ id ] || null;
  }

  activeView(id: string): boolean {
    const {viewTypes} = this.uiState;
    return viewTypes[ id ] ? viewTypes[ id ] === this.uiState.activeViewId : false;
  }

  get viewTypes(): { [ viewId: string ]: string; } {
    return this.uiState.viewTypes;
  }

  flagWaitOnAction(bool: boolean): void {
    this.uiState.waitOnAction = bool;
  }

  get waitOnAction(): boolean {
    return this.uiState.waitOnAction;
  }

  flagUnsavedChanges(bool: boolean): void {
    this.uiState.unsavedChanges = bool;
  }

  get unsavedChanges(): boolean {
    return this.uiState.unsavedChanges;
  }

  /*get leaseAvailable(): boolean {
    const isNew = this.vehicleCondition === "new";
    const isCertified = this.vehicleCondition === "certified";
    return isNew || isCertified;
  }*/

  submitSalesManagerTradeIn(tradeIn: Partial<TradeIn>) {
    this.dealService.submitSalesManagerTradeIn(tradeIn);
  }

  submitSalesManagerTradeInOwner(owner: Partial<Buyer>) {
    this.dealService.submitSalesManagerTradeInOwner(owner);
  }

  submitSalesManagerTradeInOwner2(owner: Partial<Buyer>) {
    this.dealService.submitSalesManagerTradeInOwner2(owner);
  }

  submitSalesManagerTradeIn2(tradeIn: Partial<TradeIn>) {
    this.dealService.submitSalesManagerTradeIn2(tradeIn);
  }

  submitSalesManagerTradeIn2Owner(owner: Partial<Buyer>) {
    this.dealService.submitSalesManagerTradeIn2Owner(owner);
  }

  submitSalesManagerTradeIn2Owner2(owner: Partial<Buyer>) {
    this.dealService.submitSalesManagerTradeIn2Owner2(owner);
  }

  /**
   * Copies the address from the buyer to the co buyer.
   * Deep copy is necessary to trigger change detection for the buyer form.
   */
  copyAddress($event, source, dest) {
    $event.stopPropagation();
    $event.preventDefault();
    if (source && dest) {
      //console.log("Copying Address:", source, dest)
      dest.street = source.street;
      dest.city = source.city;
      dest.county = source.county;
      dest.state = source.state;
      dest.zip = source.zip;
      this.flagUnsavedChanges(true);
    }
    return dest;
  }

  /**
   * Copies the address from the buyer to the co buyer.
   * Deep copy is necessary to trigger change detection for the buyer form.
   */
  copyProfile($event, source, dest) {
    $event.stopPropagation();
    $event.preventDefault();
    if (source && dest) {
      dest.firstName = source.firstName;
      dest.middleName = source.middleName;
      dest.lastName = source.lastName;
      dest.email = source.email;
      dest.phone = source.phone;
      dest.street = source.street;
      dest.city = source.city;
      dest.county = source.county;
      dest.state = source.state;
      dest.zip = source.zip;
      dest.birthdate = source.birthdate;
      dest.dmsContactId = source.dmsContactId;
      dest.driverId = source.driverId;
      this.flagUnsavedChanges(true);
    }
    return dest;
  }

  /**
   * Deep copies an object and maintains data type of cloned object.
   */
  deepCopy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map(item => this.deepCopy(item))
      : source instanceof Date
        ? new Date(source.getTime())
        : source && typeof source === "object"
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
            Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
            o[ prop ] = this.deepCopy((source as { [ key: string ]: any })[ prop ]);
            return o;
          }, Object.create(Object.getPrototypeOf(source)))
          : source as T;
  }

  printDisclosure() {
    //console.log("Printing Disclosure...");

    combineLatest([
      this.dealService.selectDeal(),
      this.vehicleService.selectVehicle(),
      this.appService.selectFinancing(),
      this.calcService.totalVehicleFinancePrice$({withoutDaysToFirstPay: true, actualTrade: true}),
      this.dealService.selectFinanceOptionsEdits(),
      this.calcService.calcFees$(),
      this.calcService.calcTax$(),
      this.calcService.customizedVehiclePrice$(),
      this.calcService.vehicleBaseFinanceAmount$(),
      this.calcService.findInterestRate$(),
      this.store.select(dealSelectors.selectTradeEquityEstimate),
      this.calcService.baseVehiclePrice$(),
      this.calcService.vehicleBaseFinanceAmount$(),
      this.calcService.totalVehicleFinancePrice$({withoutDaysToFirstPay: true}),
      this.leaseCalculationService.calcGrossCapCost$(),
      this.calcService.calcTax$({basePriceOnly: true}),
    ])
      .pipe(take(1))
      .subscribe((
        [
          deal,
          vehicle,
          financingSettings,
          totalFinanced,
          financeOptionsEdits,
          fees,
          taxes,
          customizedVehiclePrice,
          baseFinanceAmount,
          interestRate,
          tradeEquity,
          baseVehiclePrice,
          vehicleBaseFinanceAmount,
          totalVehicleFinancePrice,
          grossCapCost,
          taxesBasePriceOnly
        ]) => {

        if (!vehicle.vin)
          return;

        /*
        console.log("Tier 1 Values...")
        console.log('deal:', deal)
        console.log('vehicle:', vehicle)
        console.log('financingSettings:', financingSettings)
        console.log('totalFinanced:', totalFinanced)
        console.log('fees:', fees)
        console.log('taxes:', taxes)
        console.log('customizedVehiclePrice:', customizedVehiclePrice)
        console.log('baseFinanceAmount:', baseFinanceAmount)
        console.log('interestRate:', interestRate)
        console.log('tradeEquity:', tradeEquity)
        console.log('baseVehiclePrice:', baseVehiclePrice)
        console.log('vehicleBaseFinanceAmount:', vehicleBaseFinanceAmount)
        console.log('totalVehicleFinancePrice:', totalVehicleFinancePrice)
        console.log('grossCapCost:', grossCapCost)
        console.log('taxesBasePriceOnly:', taxesBasePriceOnly);
         */


        // rebates
        const {adjustedPrice: rebates} = this.incentivesService.applyCashIncentives({
          price: 0,
          incentives: this.deal.incentives,
          leaseOptions: this.deal.leaseOptions,
          leaseSelected: this.deal.leaseOptions.leaseSelected,
          financeOptions: this.deal.financeOptions
        });

        //console.log("Rebates:", rebates)

        let dealType = 'cash';

        if (deal.financeOptions.cashPurchase) {
          dealType = 'cash';
        }
        if (deal.financeOptions.financeSelected) {
          dealType = 'finance';
        } else if (deal.leaseOptions.leaseSelected) {
          dealType = 'lease';
        }

        const approvedProducts = this.calcService.filterProductsByType(this.deal.insuranceProducts, dealType);

        const dealerAccessories = this.vehicleService.parsePBSCustomFields(vehicle);

        let accessories = [];

        if (dealerAccessories) {
          dealerAccessories.forEach(acc => {
            if (deal.selectedDealerAccessories && deal.selectedDealerAccessories.includes(acc.name)) {
              accessories.push(acc);
            }
          });
        }
        if (deal.accessories) {
          deal.accessories.forEach(acc => {
            if (!acc.disabled) {
              accessories.push(acc);
            }
          });
        }

        // if declinedProducts is undefined or null, set to empty array
        let declinedProducts = [];

        const leaseTerm = this.deal.leaseOptions?.selectedLeaseTerm;

        // let moneyFactor = this.leaseCalcService.calcMoneyFactorLogic(leaseTerm, financingSettings, deal, vehicle);

        //console.log('leaseTerm:', leaseTerm)
        //console.log('moneyfactor:', moneyFactor)

        // iterate through vehicle's insurance products, and if any are missing in the deal and not in the declined array, then add it to the declined array
        if (this?.deal?.insuranceProducts && vehicle.insuranceProducts) {
          vehicle.insuranceProducts.forEach(vehicleProduct => {
            let found = false;
            this?.deal?.insuranceProducts.forEach(product => {
                if (product.productKey === vehicleProduct.productKey) {
                  found = true;
                }
              }
            );
            if (!found) {
              if (Array.isArray(declinedProducts)) {
                found = false;
                declinedProducts.forEach(product => {
                  if (product.productKey === vehicleProduct.productKey) {
                    found = true;
                  }
                });
                if (!found) {
                  declinedProducts.push(vehicleProduct);
                }
              }
            }
          });
        }

        declinedProducts = this.calcService.filterProductsByType(declinedProducts, dealType);

        combineLatest([
          this.leaseCalculationService.calcBaseMonthlyLeasePayment$({term: deal.leaseOptions.selectedLeaseTerm}),
          this.calcService.baseMonthlyPayment$(deal.financeOptions.selectedFinancingTerm),
          this.calcService.calculateTotalVehicleFinanceMonthlyPayment$(),
          this.leaseCalculationService.calcCCR$(),
          this.store.select(dealSelectors.selectTradeEquityEstimate),
          this.leaseCalculationService.residualValue$(deal.leaseOptions.selectedLeaseTerm),
        ])
          .pipe(take(1))
          .subscribe((
            [
              baseMonthlyLeasePayment,
              baseMonthlyFinancePayment,
              totalVehicleFinanceMonthlyPayment,
              ccr,
              tradeEquityEstimate,
              residualValue,
            ]) => {
            // console.log("Tier 2 Values...")
            // console.log('dealType:', dealType)
            // console.log('baseMonthlyLeasePayment:', baseMonthlyLeasePayment)
            // console.log('baseMonthlyFinancePayment:', baseMonthlyFinancePayment)
            // console.log('totalVehicleFinanceMonthlyPayment:', totalVehicleFinanceMonthlyPayment)
            // console.log('approvedProducts:', approvedProducts)
            // console.log('declinedProducts:', declinedProducts)
            // console.log('accessories:', accessories)

            combineLatest([
              this.leaseCalculationService.calcTotalMonthlyLeasePayment$(),
            ])
              .pipe(take(1))
              .subscribe((
                [
                  totalMonthlyLeasePayment,
                ]) => {

                const monthlyProductLeaseCostByName = [];

                let dueAtSigning = 0;

                if (dealType === 'lease') {

                  let rebates = 0;

                  const {adjustedPrice, incentivesApplied} = this.incentivesService.applyCashIncentives({
                    price: 0,
                    incentives: this.deal.incentives,
                    leaseOptions: this.deal.leaseOptions,
                    leaseSelected: this.deal.leaseOptions.leaseSelected,
                    financeOptions: this.deal.financeOptions
                  });
                  rebates = Math.abs(adjustedPrice);

                  dueAtSigning = this.deal.financeOptions.downPayment + ((this.deal.tradeIn.tradeValue > 0) ? this.deal.tradeIn.tradeValue : 0) + rebates;

                  const allProducts = approvedProducts.concat(declinedProducts);

                  let obs = [];
                  allProducts.forEach(product => {
                    //console.log('product', product)
                    monthlyProductLeaseCostByName[ product.name ] = 0;
                    obs.push(this.leaseCalculationService
                      .calculateInsuranceProductMonthlyPaymentForLease$(
                        product,
                        leaseTerm
                      ));
                  });

                  combineLatest(obs)
                    .pipe(take(1))
                    .subscribe((
                      results) => {
                      // console.log('results:', results)
                      allProducts.forEach((product, i) => {
                        monthlyProductLeaseCostByName[ product.name ] = results?.[ i ];
                      });

                      const pdf = new PrintDisclosureForm(this.calcService);

                      const pd: PrintDisclosure = {
                        financeOptionsEdits,
                        totalFinanced,
                        deal,
                        vehicle,
                        fees,
                        taxes,
                        customizedVehiclePrice,
                        baseFinanceAmount,
                        interestRate,
                        tradeEquity,
                        baseVehiclePrice,
                        baseMonthlyLeasePayment,
                        vehicleBaseFinanceAmount,
                        baseMonthlyFinancePayment,
                        totalVehicleFinancePrice,
                        totalVehicleFinanceMonthlyPayment,
                        approvedProducts,
                        declinedProducts,
                        accessories,
                        dealType,
                        grossCapCost,
                        monthlyProductLeaseCostByName,
                        financingSettings,
                        totalMonthlyLeasePayment,
                        ccr,
                        tradeEquityEstimate,
                        dueAtSigning,
                        taxesBasePriceOnly,
                        residualValue,
                        rebates
                      };
                      pdf.generatePdf(pd, "open");
                      
                    });

                } else {
                  const pdf = new PrintDisclosureForm(this.calcService);

                  const pd: PrintDisclosure = {
                    financeOptionsEdits,
                    totalFinanced,
                    deal,
                    vehicle,
                    fees,
                    taxes,
                    customizedVehiclePrice,
                    baseFinanceAmount,
                    interestRate,
                    tradeEquity,
                    baseVehiclePrice,
                    baseMonthlyLeasePayment,
                    vehicleBaseFinanceAmount,
                    baseMonthlyFinancePayment,
                    totalVehicleFinancePrice,
                    totalVehicleFinanceMonthlyPayment,
                    approvedProducts,
                    declinedProducts,
                    accessories,
                    dealType,
                    grossCapCost,
                    monthlyProductLeaseCostByName,
                    financingSettings,
                    totalMonthlyLeasePayment,
                    ccr,
                    tradeEquityEstimate,
                    dueAtSigning,
                    taxesBasePriceOnly,
                    residualValue,
                    rebates
                  };
                  pdf.generatePdf(pd, "open");
                }
              });
          });
      });
  }

  criticalMemo = () => {
    //console.log("Showing Critical Memo Dialog")
    let confirmed = true;
    if (!this.salesManagerVerified) {
      confirmed = confirm("Not all changes are approved. Continue?");
    }
    if (confirmed) {
      if (this.waitOnAction || this.unsavedChanges) { return; }
      this.flagWaitOnAction(true);

      // disables additional critical memo handling for now.
      if (false && this.additionalCriticalMemoText) {
        // if there is new additional critical memo text, show the confirmation modal.
        document.getElementById("openModalButton").click();
        //console.log(`Current Vehicle Critical Memo (if any):\n${this.currentCriticalMemoText}`)
        //console.log(`Final Vehicle Critical Memo (if any):\n${this.additionalCriticalMemoText}`)
      } else {
        // otherwise, just send the deal out for approval
        this.dealService.dispatchApproveDeal(this.deal.dealId, this.currentCriticalMemoText);
      }
    }
  };

  updateCriticalMemo = () => {
    // Critical Memo Field Modal disabled until PBS can support it.
    // console.log("Updating Critical Memo for vehicle:", this.additionalCriticalMemoText);
    // document.getElementById("openModalButton").click();
    this.dealService.dispatchApproveDeal(this.deal.dealId, this.additionalCriticalMemoText);
  };
}
