import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationError, Params, Router, RouterEvent } from "@angular/router";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppService, VehicleService } from "./clearpath-module/services";
import { AuthService } from "./auth-module/services";
import { User } from "./user-admin-module/models";
import { PubnubService } from "./shared-module/services";
import { USER_ROLES } from "./app.config";
import { Vehicle } from "./clearpath-module/models";
import * as rg4js from "raygun4js";
import { consoleLog, FeatureFlag, getCurrentEnv, isFlagEnabled } from "./util/featureFlags";

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject();

  currentRelease: string = "2.0.16";
  currentUser: User;
  currentRoute: string;

  initialDataLoaded: boolean = false;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private router: Router,
    private vehicleService: VehicleService,
    private pubsub: PubnubService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    console.log('---------------------------------------------------');
    console.log("Starting: Northstar_Release_" + this.currentRelease);
    console.log(`ENV: ${getCurrentEnv()}`);
    consoleLog(`FEATURE FLAGS:`);
    consoleLog(`◦ CONSOLE_LOG: ${isFlagEnabled(FeatureFlag.CONSOLE_LOGS)}`);
    consoleLog(`◦ VITU: ${isFlagEnabled(FeatureFlag.VITU)}`);
    this.setToken();
    this.subToCurrentUserAndVehicles();
    this.subToRouterEvents();
    this.pubsub.watchMsgChannel();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // login with param token or try to reuse token on refresh
  setToken() {
    // subscribe to router event and extract token from params if exists
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.token) {
        this.authService.loginWithToken(params.token);
      } else {
        this.authService.reuseToken();
      }
    });
  }

  subToCurrentUserAndVehicles() {
    combineLatest([
      this.authService.selectUser(),
      this.vehicleService.selectVehicles()
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([user, vehicles]: [User, Vehicle[]]) => {
        this.currentUser = user;
        if ((!vehicles || !vehicles.length) && user) {
          this.vehicleService.dispatchGetAllVehicles();
        }

        if (user && !this.initialDataLoaded) {
          this.initialDataLoaded = true;
          this.appService.getAppSettingsData();
        }
      });
  }

  get isSalesManager(): boolean {
    if (this.currentUser) {
      const userRoles = User.unpackSecurityGroups(this.currentUser.securityGroups);
      if (userRoles.includes(USER_ROLES.sales_manager)) {
        return true;
      }
    }
  }

  subToRouterEvents() {
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: RouterEvent) => {
        const navigationEnd = event instanceof NavigationEnd;
        const includesUrl = event.url !== undefined;
        if (navigationEnd && includesUrl) {
          this.currentRoute = event.url;
        }

        if (event instanceof NavigationEnd) {
          rg4js("trackEvent", {
            type: "pageView",
            path: event.url
          });
        }

        // Track navigation errors when the NavigationError event occurs
        if (event instanceof NavigationError) {
          // Track navigation error
          rg4js("send", {
            error: event.error
          });
        }
      });
  }
}
