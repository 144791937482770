import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { DealService } from "../../../../clearpath-module/services";
import { DealState, DealType } from "../../../../clearpath-module/store/state";
import { Lender } from "../../../models/data";
import { LENDERS } from "../../../data/lenders";
import { initialLienHolderState } from "../../../../clearpath-module/store/state/deal/financing";

@Component({
  selector: 'app-deal-type-selector',
  templateUrl: './deal-type-selector.component.html',
  styleUrls: ['./deal-type-selector.component.scss']
})
export class DealTypeSelectorComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  @Output() submitDealType = new EventEmitter<string>();
  private deal: DealState;

  formGroup: FormGroup = this.formBuilder.group({
    selectedDealType: [undefined],
  });

  constructor(private formBuilder: FormBuilder, private dealService: DealService,) { }

  ngOnInit(): void {
    this.dealService.selectDeal().pipe(
      takeUntil(this.unsubscribe$),
      map(dealState => {
        this.deal = dealState;
        if (this.deal.financeOptions.financeSelected) {
          this.formGroup.get('selectedDealType')?.setValue(DealType.Finance);
        } else if (this.deal.leaseOptions.leaseSelected) {
          this.formGroup.get('selectedDealType')?.setValue(DealType.Lease);
        } else if (this.deal.financeOptions.cashPurchase) {
          this.formGroup.get('selectedDealType')?.setValue(DealType.Cash);
        } else {
          this.formGroup.get('selectedDealType')?.setValue(undefined);
        }
      })).subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get lenders(): Lender[] {
    return LENDERS;
  }

  getIndexForLenderName(lenderName: string): number {
    return this.lenders.findIndex(lender => lender.name === lenderName);
  }

  onSelectedDealTypeChange(event: any) {
    const newDealType: string = event.value;
    console.log('onSelectedDealTypeChange', newDealType);
    switch (newDealType) {
      case DealType.Finance:
        this.submitDealType.emit(DealType.Finance);
        if (this.deal.lienHolder.name === "*TOYOTA LEASE TRUST") {
          this.dealService.dispatchSetLienHolder(this.lenders[ this.getIndexForLenderName("*TOYOTA MOTOR CREDIT CORPORATION") ]);
        }
        break;
      case DealType.Lease:
        this.submitDealType.emit(DealType.Lease);
        this.dealService.dispatchSetLienHolder(this.lenders[ this.getIndexForLenderName("*TOYOTA LEASE TRUST") ]);
        break;
      case DealType.Cash:
        this.submitDealType.emit(DealType.Cash);
        if (this.deal.lienHolder.name === "*TOYOTA LEASE TRUST") {
          this.dealService.dispatchSetLienHolder({...initialLienHolderState});
        }
        break;
    }
    this.formGroup.get('selectedDealType')?.setValue(newDealType);
  }
}
